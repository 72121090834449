import React, { useState, useEffect } from "react"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { Box, Flex, Grid } from "@theme-ui/components"

const VerticalLines = () => {
  const breakpointIndex = useBreakpointIndex()
  let nrItems =
    breakpointIndex === 3
      ? 8
      : breakpointIndex === 2
      ? 6
      : breakpointIndex === 1
      ? 4
      : 3

  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 0,
      }}
      gap={[0]}
      columns={[nrItems, nrItems, nrItems]}
    >
      {[...Array(nrItems)].map((lineItem, index) => (
        <Box
          sx={{
            height: "100%",
            borderLeft: (index !== 0 || index !== nrItems) && ".5px solid",
            borderColor: (index !== 0 || index !== nrItems) && "lightGrey",
          }}
        ></Box>
      ))}
    </Grid>
  )
}

export default VerticalLines
