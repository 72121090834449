import React from "react"
import { Parallax } from "react-scroll-parallax"

const BlockParallax = props => {
  const children = props.children
  return (
    <Parallax {...props} >
      {children}
    </Parallax>
  )
}

export default BlockParallax
